import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, className, tag: Tag }) => <Tag className={classnames('card', className)}>{children}</Tag>;

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.node,
};

Card.defaultProps = {
  children: null,
  className: null,
  tag: 'div',
};

export default Card;
